.overlay {
     background-color: rgba(0, 0, 0, 0.7); 
    position: fixed;
    top:0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
  }

  .modalContainer {
    position: fixed;
    width:39%;
    height: 95%;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -39%);
    display: flex;
    background-color: #ffffff;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
    border-radius: 4rem;
  }

  .modalContainer img{
        display: flex;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
  }

  .escape{
    position: fixed;
    top: 0;
    right: -2.5rem;
    z-index: 200;
    cursor: pointer;
  }
  .escape p {
    font-size: 30px;
    color:white !important ;
    margin: 0;
  }

  .Arrow_Left{
    position: fixed;
    left:-4rem;
    top:50%;
  }

  .Arrow_Right{
    position: fixed;
    right:-4rem;
    top:50%;
  }