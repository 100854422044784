.technology {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    height: 80rem;
    margin-top: 5rem;
    margin-left: 5rem;
    margin-right: 5rem;
    width: 100%;
    align-self: center;
    left: 0;
    top: 0;

}

.technology_text_white {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    color: white;
    margin-bottom: 2rem;
}

.technology_text_white spam {
    font-size: 3em;
    padding-bottom: 0rem;
    margin-bottom: 0;
}


.technology_text_white p {
    padding: 1rem 2rem 0rem;
    text-align: center;
    font-size: 24px;
    line-height: 31px;
    color: white;
}

.imageT {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.imagediv1 img {
    width: 100%;
    height: 100%;
}

.technology_img {
    width: 75%;
    height: 100%;
}

.technology_black_text {
    width: 55%;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: black;
    text-align: justify;
}



.header_text_white {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    margin-bottom: 2rem;
}

.header_text_white spam {
    font-size: 3em;
    padding-bottom: 0rem;
    margin-bottom: 0;
}


.header_text_white p {
    padding: 1rem 2rem 0rem;
    text-align: center;
    font-size: 24px;
    line-height: 31px;
    color: white;
}