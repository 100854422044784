.order_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
    color: black;
    height: 30rem;
    width: 50%;
    border-radius: 5px;
    border: 1px solid black;
    background: rgb(255, 255, 255, 0.7);
    margin-bottom: 1rem;
}