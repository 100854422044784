.tutorials {
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding-bottom: 5rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.applications_image_row {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.tutorial_processing_image_sets{
    width: 65%;
    align-self: center;
    align-items: center;
}

.tutorial_processing_image_sets ol{
    align-self: center;
    font-size: 20px;
}

.tutorials h1 {
    font-size: 40px;
    margin-top: 0;
}

.clip {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 0rem;
}
.clip iframe {
    width: 100%;
    height: 100%;
}

.clip_item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0rem 1rem;
}

.image {
    display: flex;
    flex-direction: row;
}

.image img {
    width: 100%;
    height: 100%;
}

.terms_title {
    width: 75%;
    height: 100%;
    color: black;
    align-self: center;
    display: flex;
    text-align: left;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.applications_title h1 {
    width: 100%;
    margin-top: 0;
    text-align: center;
    font-size: 40px;
}

.applications_title h2 {
    width: 100%;
    margin-top: 20px;
    text-align: center;
    font-size: 28px;
}

.applications_title div {
    border-radius: 5px;
    width: 60%;
    border: 1px solid black;
    background:  rgb(255, 255, 255, 0.7);
    margin-bottom: 1rem;
}

.terms_title p {
    font-size: 20px;
    text-align: left;
    color: black;
}

.applications_title2 {
    width: 100%;
    height: 100%;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
}

.applications_title2 h1 {
    width: 100%;
    margin-top: 0;
    text-align: center;
    font-size: 40px;
}

.applications_title2 h2 {
    width: 100%;
    margin-top: 20px;
    text-align: center;
    font-size: 28px;
}

.applications_title2 div {
    border-radius: 5px;
    width: 60%;
    border: 1px solid black;
    background:  rgb(255, 255, 255, 0.7);
    margin-bottom: 1rem;
}

.applications_title2 p {
    font-size: 20px;
    text-align: left;
    width: 60%;
    margin-bottom: 1rem;
}

.prog_gallery_images_small {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.prog_gallery_images_small_2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.prog_gallery_images_big {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.prog_gallery_images_small img {
    height: 32.7rem;
    width: auto;
}

.prog_gallery_images_small_2 img {
    height: 30rem;
    width: auto;
}

.prog_gallery_images_big img {
    height: 40rem;
    width: auto;
}


