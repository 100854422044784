.cqc__navbar_second {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-around;
    margin-left: 150px;
    margin-right: 150px;
    border-bottom: 1px solid #5b9bd5;
}

.cqc__navbar_second p {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.cqc__navbar-links_container  {
    color: #204e79;
    font-size: 18px;
    text-transform: uppercase;
    font-family:  Roboto Regular,Helvetica,Arial,sans-serif;
    
    cursor: pointer;
}

.cqc__navbar-links_container2 {
    color: #204e79;
    font-size: 18px;
    text-transform: uppercase;
    font-family:  Roboto Regular,Helvetica,Arial,sans-serif;
    cursor: pointer;
}
.cqc__navbar-links_container p:hover {
    color: #FF4820;
}

.abbvie_logo {
    margin-top: 0;
}

.abbvie_logo img {
    height: 70px;
}
