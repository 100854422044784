.cqc__footer {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 100%;
}
.cqc__footer h3{
    padding: 2rem 2rem;
    margin: 0;
    text-align: center;
}

.cqc__footer-row  {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;   
    text-align: center;
    margin-left: 50px;
    margin-right: 50px;

    border-top: 1px solid #5b9bd5;
}

.cqc__footer-row div {
    padding: 0rem 1rem;
}

@media  screen and (max-width: 875px) {
    .cqc__footer-row div {
        padding: 0rem 1rem;
    }
}

@media  screen and (max-width: 550px ) {
    .cqc__footer h3{
        font-size:large;
    }
    .cqc__footer-row p {
        font-size:14px ;
    }
}

@media  screen and (max-width: 450px) {
    .cqc__footer-row  {
        flex-direction: column-reverse;
    }
    .cqc__footer-row p {
        font-size: 15px;
        margin: 0.2rem;
    }

    .cqc__footer h3 {
        padding: 1rem 2rem;
    }
}

@media  screen and (max-width: 335px) {
    .cqc__footer h3 {
       font-size: medium;
    }
}
