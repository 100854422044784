.bro{
    display: flex;
    justify-content: center;
    padding-top: 2rem;
}
.Submission {
    display:flex;
    flex-direction: column;
    max-width: 65rem;
    padding: 0 2rem;
}

.Submission_img{
    display: flex;
    flex-direction: row;
}

.Submission_img img {
    width: 100%;
    height: 100%;
}

.submission_text_block {
    font-size: 15px;
    width: 100%;
    height: 5rem;
}

.submission_text_block_small {
    font-size: 15px;
    width: 50%;
}
p {
    font-size: 18px;
}
.Submission_Description{
    padding-bottom: 4rem;
}
.Submission_Upload{
    display: flex;
    flex-direction: row;
    padding-bottom: 2rem;
}

.Submission_images{
    display: flex;
    flex-direction: row;
    max-height: 30rem;
}

.Submission_images img {
    width: 100%;
    height: 100%;
    margin: 1rem;
}
.sub{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.Sub {
    margin: 1rem;
    padding: 0.8rem;
    font-size: 20px;
    width: 50%;
    background-color: #FF4820;
    align-self: center;
    color: white;
    border: none;
    border-radius: 4px;  
    cursor: pointer;
}

.Second_row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 75%;
  }

  .image_row {
    display: flex;
    width: 32%;
    flex-direction: column;
    justify-content: start;
    vertical-align: middle;
  }

  .cqc__p {
    display: flex;
    justify-content: center;
  }
  .cqc__p p {
    align-items: center;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 10px;
  }
.Back {
    margin: 1rem;
    padding: 0.8rem;
    font-size: 20px;
    align-self: center;
    background-color: #163278;
    color: white;
    width: 50%;
    border: none;
    border-radius: 4px;  
    cursor: pointer;
  }


  

  


